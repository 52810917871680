$emerge-blue: #12D6FF;
$sm: 576px;

body {
  font-family: 'Source Sans Pro', sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
}


h2 {
  font-weight: 900;
  line-height: 2rem;
}

h4 {
  font-weight: 900;
}

.main-container {
  max-width: 1200px;
  margin-left: auto;
  margin-right: auto;
}

#header-emerge-logo {
  height: 40px;
}

.header-socials-logo {
  height: 20px;
  width: 20px;
}

@media (min-width: $sm) {
  #buy-nft{
    margin-left: 3rem;
    margin-right: 3rem;
  }
}

.bg-emerge-blue {
  background-color: $emerge-blue;
}

.alert-emerge-blue {
  color: #000;
  background-color: $emerge-blue;
  border-color: $emerge-blue;
}

.btn-mint-lg {
  padding: 22px 67px 24px 67px;
}

.btn-outline-emerge-blue {
  color: #FFF;
  border-color: $emerge-blue;
  font-weight: 900;
  padding: 11px 0 13px 0;
  width: 236px;
  border-radius: 0.6rem;
}

.btn-outline-emerge-blue:hover {
  color: #000;
  background-color: $emerge-blue;
  border-color: $emerge-blue;
  font-weight: 900;
}

.btn-outline-emerge-warning {
  font-weight: 900;
  padding: 11px 0 13px 0;
  width: 236px;
  border-radius: 0.6rem;
}

.btn-outline-emerge-blue-black {
  color: #000;
  background-color: $emerge-blue;
  border-color: #000;
  border-width: 4px;
  font-weight: 900;
  border-radius: 0.6rem;
}

.btn-outline-emerge-blue-black:hover {
  color: #FFF;
  background-color: #000;
  border-color: #000;
}

.rarity-card {
  border: none;
  border-radius: none;
  margin-right: 0.9rem !important;
  margin-left: 0.9rem !important;
  min-width: 100px;
}

.rarity-card > .card-body {
  background-color: #000;
  color: #FFF;
  font-size: 0.75rem;
}

.rarity-img {
  border-radius: none;
  height: 74px;
  object-fit: contain;
  background-color: #000;
}

.owned-emerge-punk-img {
  border-radius: none;
  height: 125px;
  object-fit: contain;
  background-color: #000;
}

.owned-emerge-punk-img-blue {
  background-color: $emerge-blue;
}

.quickstart-card {
  background-color: #000;
  color: #FFF;

  .card-body {
    padding: 0;
    display: flex;
    align-items: center;
  }

  p {
    margin-bottom: 0px;
  }
}

.quickstart-number {
  background-color: $emerge-blue;
  color: #000;
  height: 70px;
  width: 68px;
  display: flex;
  font-size: 3rem;
  font-weight: 900;
  position: relative;

  div {
    position: relative;
    top: -2px;
  }
}

#img-mint-emergepunk {
  width: 232px;
  background-color: #000;
}

#footer > hr {
  background-color: #979797;
}

#footer > p {
  font-size: 0.75rem;
}

.react-modal {
  display: block;
  top: 100px;
}

#mint-modal-close-btn {
  position: absolute;
  right: 15px;
  top: 15px;
}

.modal-content {
  outline: $emerge-blue 2px solid;
  box-sizing: border-box;
  position: relative;
}

.modal-footer,
.modal-header {
  border-bottom: 0;
  border-top: 0;
  padding: 0;
}

.modal-title {
  color: #000;
  font-weight: 600;
  font-size: 2rem;
}

.react-modal > .modal-overlay {
  height: 100vh;
  width: 100vw;
  background: black;
  position: fixed;
  top: 0px;
  left: 0px;
}

// .react-modal > .modal-overlay {
//   width: 100%;
//   height: 100%;
//   position: absolute;
//   top: 0;
//   left: 0;
// }

// .react-modal.modal-fade {
//   animation: fade-in 1s 1 linear;
//   animation-fill-mode: forwards;
//   opacity: 0;
// }

// @keyframes fade-in {
//   0% {
//     animation-timing-function: cubic-bezier(0.2242, 0.7499, 0.3142, 0.8148);
//     opacity: 0;
//   }
//   100% {
//     opacity: 1;
//   }
// }

